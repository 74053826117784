.footer{
  font-size: 14px;
  padding-right: 50px;
  padding-bottom: 16px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: auto;
}