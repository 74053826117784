.app-card {
  display: block;
  flex-direction: column;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  padding: 0;
  text-decoration: none;
  transition-duration: 0.28s;
  transition-property: box-shadow, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 0;
  border-color: rgba(47, 43, 61, 0.16);
  border-style: solid;
  border-width: 0;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  color: rgba(47, 43, 61, 0.68);
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
  padding: 1.5rem 1.25rem 1.25rem;
  height: 100%;
}

.restart-button {
  background: none;
  border: none;
  outline: none;
  color: var(--primary-custom-color);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  padding: 0.01rem 0 !important;
  display: flex;
  align-items: center;
  height: 5px;
}

.action-button{
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: green;
  font-size: 1rem;
  font-weight: 600;
}

.stop{
  color: var(--error-color);
}

.start{
  color: var(--success-color);
}

.disabled{
  color: var(--primary-light-grey) !important;
  cursor: no-drop;
}