/* .markdown-wrapper {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
} */

/* .markdown-content {
  background-color: #fefefe;
  margin-left: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
  max-width: 60%;
} */

.markdowns .p-dialog {
  width: 50% !important;
  max-width: none !important;
}

.markdowns .p-dialog-content {
  max-height: none !important;
  width: 100% !important;
  border-radius: 0 !important;
  max-width: none !important;
}

.markdowns .p-dialog .p-dialog-header {
  border-radius: 0 !important;
}

.markdown-content img {
  width: 100%;
  height: auto;
}

.markdown-content h1 {
  font-size: 1.25rem;
}
.markdown-content p,
.markdown-content li {
  font-size: 0.9rem;
}

.markdown-content li p img,
.markdown-content li img {
  width: 1.5rem;
}

.user-manual-viewer-button {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem;
}

.user-manual-button-wrapper {
  position: relative;
  cursor: pointer;
}

/* .user-manual-button-wrapper .user-manual-viewer-button {

} */

.user-manual-button-tip {
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  background: #ffffff;
  z-index: 1000;
  color: #000000;
  width: 3rem;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 0.2rem;
  padding: 0;
}

.user-manual-button-tip p {
  margin: 0.5rem 0.25rem;
  font-weight: 500;
}
