.privacy{
  text-align: start;
}

.privacy-title{
  text-transform: capitalize;
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 8px;
}

.privacy-text{
  font-size: 14px;
}

.privacy-actions{
  display: flex;
  align-items: center;
  border-top: 0.5px solid #ccc;
  padding-top: 8px;
  justify-content: space-between;
}

.privacy-checkbox{
  display: flex;
  align-items: center;
}

.privacy-button{
  width: auto;
  min-width: 200px;
}