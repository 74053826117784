.delete-button {
  background: none;
  border: none;
  outline: none;
  font-weight: bold;
  color: #ac0303;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 350px;
  padding: 0;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.delete-button:enabled:hover {
  background: rgba(172, 3, 3, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.delete-button:enabled:active {
  background: rgba(172, 3, 3, 0.68);
  color: #ffffff;
  border-color: transparent;
}

.delete-button.button-outlined {
  background-color: transparent;
  color: var(--primary-custom-color);
  border: 1px solid;
}
.delete-button.button-outlined:enabled:hover {
  background: rgba(3, 113, 172, 0.04);
  color: var(--primary-custom-color);
  border: 1px solid;
}
.delete-button.button-outlined:enabled:active {
  background: rgba(3, 113, 172, 0.16);
  color: var(--primary-custom-color);
  border: 1px solid;
}
