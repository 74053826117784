.cookie{
  position: fixed;
  bottom: 24px;
  left: 24px;
  padding: 12px 24px;
  background: white;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  font-size: 12px;
  z-index: 1100;
  border-radius: 16px;
  text-align: start;
}

.cookie-heading{
  font-size: 16px;
  font-weight: 600;
  margin: 8px 0;
}

.cookie-actions{
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}