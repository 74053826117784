body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "rubik";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: "nunito";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/nunito_Sans/NunitoSans-Regular.ttf");
}

:root {
  --primary-custom-color: #0371ac;
  --primary-dark-color: #221ed9;
  --primary-light-color: #777bf1;
  --primary-text-color: #063a4f;
  --text-color: #063a4f;
  --text-color-secondary: #063a4f;
  --primary-color: #464df2;
  --primary-color-text: #ffffff;
  --primary-grey: rgba(157, 161, 171, 1);
  --primary-light-grey: rgba(157, 161, 171, 0.4);
  --primary-font-family: "nunito", sans-serif;
  --error-color: #f44435;
  --success-color: #43be09;
  --completed-color: #50b8ee;
  --pending-color: #f4803c;
  --warning-color: #fab417;
  --body-color-dark: rgba(119, 123, 241, 0.1);
  --body-color: rgba(127, 131, 237, 0.08);
}

.centralize {
  margin: 0 auto !important;
}

/*background-color: var(--primary-color);*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-logo {
  width: 4rem;
  height: auto;
}

.app-name {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  color: #6d8c98;
  margin-bottom: 1rem !important;
}

.width-100P {
  width: 100%;
}

.p-dropdown,
.p-multiselect {
  border: 1px solid #e1ecfd !important;
  height: 48px;
}

.p-dropdown-label,
.p-multiselect-label-container {
  margin-top: 0.3em !important;
}
.custom-dialog-subtitle-container {
  margin-top: -0.2em;
}
.custom-dialog-subtitle-container-lg {
  margin-top: -0.7em;
}
.pull-up-element-2 {
  position: relative;
  top: -3.5em;
}
/* ToastDemo.css */

.toast-demo button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

.dcir-show {
  display: inline;
}
.dcir-hide {
  display: none;
}

@media screen and (max-width: 960px) {
  .toast-demo button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.custom-dialog-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  /* identical to box height */
  color: var(--primary-text-color);
}

.p-dropdown-label,
.p-dropdown-items {
  font-family: var(--primary-font-family) !important;
  font-weight: normal !important;
  font-size: 13px !important;
  vertical-align: middle !important;
  line-height: 15px !important;
}

.p-multiselect {
  width: 100% !important;
  text-align: left !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 15px !important;
}
.p-multiselect-items {
  font-family: var(--primary-font-family) !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 15px !important;
}

.p-dropdown {
  width: 100% !important;
  text-align: left !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 15px !important;
}

input {
  border: 1px solid #e1ecfd;
  height: 48px;
  border-radius: 4px;
  font-family: var(--primary-font-family);
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  box-sizing: border-box;
}

input:focus {
  border-color: var(--primary-custom-color) !important;
}

textarea {
  border: 1px solid #e1ecfd;
  min-height: 155px;
  border-radius: 4px;
  font-family: var(--primary-font-family);
  width: 100%;
  padding: 12px;
  display: inline-block;
  box-sizing: border-box;
  resize: none;
}

textarea:focus {
  border-color: var(--primary-custom-color) !important;
}

.floating-buttons {
  position: relative;
  /* top: -2em; */
  margin-bottom: 1.5rem;
}

.floating-mobile-buttons {
  float: right;
  margin-top: -3.2em;
}

.custom-over-flow-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Primary Blue */
  color: var(--primary-text-color);
}

.custom-over-flow-text:hover {
  color: var(--primary-custom-color);
}

.p-overlaypanel-content {
  text-align: left;
  padding: 1em 0.2em 0.5em 0.5em !important;
}

.success-message-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-top: 23px;
  /* identical to box height */
  color: var(--primary-text-color);
}
.success-message-btn-container {
  padding: 0 40px;
}
.success-message-btn {
  margin-top: 54px;
}

.quick-action-card {
  margin-top: 26px;
  min-height: 300px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 5px;
  padding: 13px 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input,
textarea,
div,
/*span,*/
button {
  font-family: var(--primary-font-family) !important;
}

.p-menuitem-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Text Blue */
  color: var(--primary-custom-color) !important;
}

.custom-form-container {
  margin-top: 15px;
  padding-bottom: 10px;
}

.custom-table-card {
  background: #ffffff;
  border: 0.7px solid #e7edf7;
  min-height: 300px;
  text-align: left;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 37px 33px 24px 24px;
}

.go-back-icon-text {
  font-style: normal;
  margin-left: 9px;
  position: relative;
  top: -0.7em;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Text Blue */
  color: var(--primary-text-color);
  mix-blend-mode: normal;
}

.mobile-modal-container {
  margin-top: 40px;
}

.custom-card {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 8px;
}

.custom-page-card {
  background: #ffffff;
  border-top: 0.5px solid rgba(0, 0, 0, 0.01);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding-bottom: 1.2em;
}

.mobile-card-position {
  padding: 37px 24px;
}

.custom-modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.custom-modal-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.login-icon-position {
  position: relative;
  /* top: -em; */
}

.logo-size {
  margin: 0 auto;
  width: 150px;
}

.log-icon-top-container-modal {
  max-width: 800px !important;
  height: 60px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  margin: 0 auto;
  /*background: var(--primary-custom-color);*/
}

.p-dialog-header {
  padding: 0 !important;
  border-top-right-radius: 20px !important;
  /*border-radius: 8px!important;*/
  /*background: var(--primary-custom-color);*/
}

.custom-modal-content {
  padding: 13px 20px !important;
  padding-bottom: 15px !important;
}

.custom-modal-item {
  margin-top: -0.1em;
}

.p-dialog,
.p-dialog-content {
  width: 550px !important;
  min-width: 500px !important;
  max-width: 600px !important;
  max-height: 100% !important;
  padding: 0 !important;
  box-shadow: none !important;
  border-bottom-left-radius: 20px;
  /*border-bottom-right-radius:8px;*/
  /*border-top-left-radius:20px;*/
  /*border-top-right-radius:20px;*/
  /*overflow-x: hidden;*/
}

.close-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.0126316em;
  /* Primary Blue */
  color: var(--primary-custom-color);
  mix-blend-mode: normal;
}

.custom-mobile-table-card {
  background: #ffffff;
  border: 0.7px solid #e7edf7;
  /*min-height:2px;*/
  text-align: left;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
}

.dcir-row.table-header {
  background: #f9f9ff;
}

.dcir-row.table-header p {
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dcir-row {
  margin-left: 0 !important;
  width: 100% !important;
}

.recon .dcir-row > div:last-of-type .dcir-column {
  text-align: left;
}

.recon-statements .custom-badge {
  display: block;
  width: 40px !important;
  padding: 1px;
}

.disputes .custom-badge {
  width: 100%;
  display: block;
  padding: 2px;
}

.p-disabled {
  opacity: 0.7 !important;
}

.p-dropdown.p-disabled {
  background: rgb(245, 245, 245);
  cursor: not-allowed;
}

@keyframes ui-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary-custom-color);
  }
  40% {
    color: var(--primary-custom-color);
  }
  66% {
    /*stroke: #008744;*/
    stroke: var(--primary-custom-color);
  }
  80%,
  90% {
    /*stroke: #ffa700;*/
    stroke: var(--primary-custom-color);
  }
}

@keyframes custom-text-animation {
  100%,
  0% {
    color: var(--primary-custom-color);
  }
  40% {
    color: var(--primary-custom-color);
  }
  66% {
    /*color: #008744;*/
    color: var(--primary-custom-color);
  }
  80%,
  90% {
    /*color: #ffa700;*/
    color: var(--primary-custom-color);
  }
}

.verifying-credentials {
  color: var(--primary-custom-color);
  font-family: var(--primary-font-family);
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  animation-name: custom-text-animation;
  animation-duration: 6s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

.login-in {
  color: var(--primary-text-color);
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.loading-icon {
  position: relative;
  top: 0.3em;
  right: 0.3em;
}

.custom-form-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  color: var(--primary-text-color);
}

.p-breadcrumb {
  background: none !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.page-header {
  display: flex;
  justify-content: space-between;
}

.page-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.0126316em;
  color: var(--primary-text-color);
  margin-bottom: 4px;
  text-transform: capitalize;
}

.page-title-sm {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.0126316em;
  color: var(--primary-text-color);
  margin-bottom: 4px;
  text-transform: capitalize;
}

.inner-content {
  padding: 10px 49px 50px 49px;
  max-width: 1400px;
  margin: 28px auto;
  min-width: 330px;
}

.custom-font-family {
  font-family: var(--primary-font-family) !important;
}

a {
  text-decoration: none !important;
  font-family: var(--primary-font-family) !important;
  color: var(--primary-text-color);
}

.active-icons svg {
  fill: var(--primary-custom-color) !important;
}

.secondary-button {
  width: 100%;
  opacity: 0.8;
  cursor: pointer;
  font-style: normal;
  min-height: 38px;
  outline: none !important;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  /*box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);*/
  line-height: 1rem;
  /* identical to box height */
  letter-spacing: 0.000909091em;
  /* #EB5757 */
  background: rgba(235, 87, 87, 0.3);
  border: 1px solid rgba(235, 87, 87, 0.2);
  border-radius: 4px;
  color: #eb5757;
  font-weight: 600;
  font-size: 0.875rem;
}

.secondary-button:enabled:hover {
  background: rgba(235, 87, 87, 0.2);
  border-color: transparent;
}

.custom-modal-content .error-toast-text-container {
  margin-left: -0.5em;
}

.primary-button {
  color: #ffffff;
  width: 100%;
  max-width: 350px;
  cursor: pointer;
  background: var(--primary-custom-color);
  border: 0 none;
  padding: 0.571rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s,
    box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}

.tertiary-button {
  background-color: #50b8ee;
  /* background-color: #f4803c; */
  color: white;
  border: none;
  padding: 0.571rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  max-width: 250px;
}

.primary-button:enabled:hover {
  background: rgba(3, 113, 172, 0.92);
  color: #ffffff;
  border-color: transparent;
}
.primary-button:enabled:active {
  background: rgba(3, 113, 172, 0.68);
  color: #ffffff;
  border-color: transparent;
}

.primary-button.button-outlined {
  background-color: transparent;
  color: var(--primary-custom-color);
  border: 1px solid;
}
.primary-button.button-outlined:enabled:hover {
  background: rgba(3, 113, 172, 0.04);
  color: var(--primary-custom-color);
  border: 1px solid;
}
.primary-button.button-outlined:enabled:active {
  background: rgba(3, 113, 172, 0.16);
  color: var(--primary-custom-color);
  border: 1px solid;
}

.download-button {
  background-color: #50b8ee;
  /* background-color: #f4803c; */
  color: white;
  border: none;
  padding: 0.6rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
}

.primary-button:disabled {
  opacity: 0.8;
  background: rgba(3, 113, 172, 0.68);
  cursor: default;
}

.add-cursor {
  cursor: pointer !important;
}

.float-right {
  float: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.sidenav {
  height: 100%;
  padding: 0;
  width: 250px;
  position: fixed;
  /*border: 1px solid #ffffff;*/
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: #ffffff;
  transition: 0.5s;
}

.main-body {
  position: relative;
  /*top:40px;*/
  /* height: 100vh; */
  /* padding: 16px; */
}

hr {
  height: 0;
  border-top: 1px solid #bbb;
}

.p-breadcrumb {
  border: 0 !important;
}

.custom-badge {
  max-width: 120px;
  text-align: center;
  position: relative;
  top: -0.4em;
  padding: 4px 10px;
  border-radius: 4px;
}

.custom-badge-code {
  width: 32px !important;
  display: inline-block;
  padding: 5px;
}

.custom-badge-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  letter-spacing: -0.025em;
  color: #ffffff !important;
}

.custom-badge-success {
  background: var(--success-color);
}

.custom-badge-pending {
  background: var(--pending-color);
}

.custom-badge-completed {
  background: var(--completed-color);
}

.info-card {
  /* height: 66px; */
  padding: 8px 30px;
  color: #063a4f;
  border-radius: 8px;
  background: rgba(7, 180, 66, 0.3);
  font-weight: bold;
}
.info-card.accepted {
  background: rgba(7, 180, 66, 0.3);
}
.info-card.declined {
  background: rgb(244, 68, 53);
  color: #ffffff;
}
.info-card.pending {
  background: rgb(244, 128, 60);
  color: #ffffff;
}
.info-card-text {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin: 0.75rem 0;
  /* Text Blue */
  /* color: #063a4f; */
}
.info-card-text .bare-button:hover {
  text-decoration: underline;
}
.custom-badge-warning {
  background: var(--warning-color);
}
.custom-badge-error {
  background: var(--error-color);
}

#main {
  transition: margin-left 0.5s;
  position: relative;
  margin-left: 250px;
}

@media screen and (min-width: 765px) {
  .mobile-screen {
    display: none;
  }
  .desktop-screen {
    display: block;
  }
  .sidenav {
    width: 250px;
  }
  #main {
    margin-left: 250px;
  }
}

@media screen and (max-width: 765px) {
  .mobile-screen {
    display: block;
  }
  .desktop-screen {
    display: none;
  }
  .sidenav {
    width: 0;
  }
  #main {
    margin-left: 0;
  }

  /*.custom-modal-content{*/
  /*  padding: 32px 20px 15px;*/
  /*}*/
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #0371ad !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:hover {
  background: #0371ad !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff !important;
}

@media screen and (max-width: 899px) {
  button .hide-btn-text {
    display: none;
  }
}

@media screen and (max-width: 365px) {
  .table-icon-text {
    display: none;
  }
}

.custom-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.0115367em;
  /* Text Blue */
  color: var(--primary-text-color);
}
